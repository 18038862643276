import React from 'react';
import styles from './animation.module.css';

const BackgroundAnimation: React.FC = () => {
    return (
      <div className={styles.background}>

        {/* <svg className={`${styles.svg} ${styles.star}`} viewBox="0 0 100 100">
          <polygon points="50,15 61,35 82,38 66,55 71,76 50,65 29,76 34,55 18,38 39,35" fill="#FFD700" />
        </svg>
  
        <svg className={`${styles.svg} ${styles.cloud}`} viewBox="0 0 100 100">
          <ellipse cx="50" cy="60" rx="40" ry="20" fill="#FFFFFF" />
          <ellipse cx="70" cy="50" rx="30" ry="15" fill="#FFFFFF" />
        </svg>
  
        <svg className={`${styles.svg} ${styles.heart}`} viewBox="0 0 100 100">
          <path d="M50 15 L65 30 Q70 45 50 70 Q30 45 35 30 Z" fill="#FF69B4" />
        </svg> */}

      </div>
    );
};

export default BackgroundAnimation;
