// SplashScreen.tsx
import React, { useEffect, useState } from 'react';
import styles from './splash.module.css';

import BackgroundAnimation from './animation';

import { playSoundEffect } from '../../soundEffect';

interface SplashScreenProps {
    onComplete: () => void; // Callback to transition to the main content
}

const SplashScreen: React.FC<SplashScreenProps> = ({ onComplete }) => {
    const [count, setCount] = useState(3); // Start countdown from 10 seconds
    const [isFadingOut, setIsFadingOut] = useState(false);

    useEffect(() => {
        // Countdown logic
        const countdownInterval = setInterval(() => {
            setCount((prevCount) => {
                if (prevCount === 1) {
                    clearInterval(countdownInterval);
                    setIsFadingOut(true);
                }
                return prevCount > 0 ? prevCount - 1 : 0;
            });
        }, 1000);
        // Skip countdown on "Enter" key press or screen click
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
            // playSoundEffect("sfx/sword.wav", 0); // Play the sound at 50% volume
            setIsFadingOut(true);
            clearInterval(countdownInterval);
            }
        };

        const handleScreenClick = () => {
            // playSoundEffect("sfx/sword.wav", 0); // Play the sound at 50% volume
            setIsFadingOut(true);
            clearInterval(countdownInterval);
        };

        window.addEventListener('keydown', handleKeyPress);
        window.addEventListener('click', handleScreenClick);

        // Remove splash screen after fade-out
        if (isFadingOut) {
            setTimeout(() => onComplete(), 1000); // 1-second fade-out
        }

        // Cleanup on component unmount
        return () => {
            clearInterval(countdownInterval);
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [isFadingOut, onComplete]);

    return (
        <div className={`grid grid-rows-5 ${styles.splashScreen} ${isFadingOut ? styles.fadeOut : ''}`}>
            <BackgroundAnimation />
            <div className='row-span-1'></div>
            
            <div className={`row-span-1 pb-0 flex flex-col items-center space-y-2 ${styles.logo}`}>
                <h1 className={`text-8xl md:text-9xl font-extrabold ${styles.logo} ${styles.glow} stroke stroke-black`}>
                    {window.innerWidth <= 768 ? 'Degens' : 'Degens Arena'}
                </h1>
                {window.innerWidth <= 768 && (
                    <h1 className={`text-8xl md:text-9xl font-extrabold ${styles.logo} ${styles.glow} stroke-black`}>
                        Arena
                    </h1>
                )}
                <h2 className={`text-white text-xl sm:text-3xl md:text-4xl lg:text-5xl font-medium ${styles.glow}`}>Welcome to PVPclient</h2>
            </div>

            <div className='row-span-1'></div>
            <div className={`row-span-2 flex flex-col items-center space-y-2 `}>
                <p className={`text-white text-lg sm:text-xl md:text-2xl lg:text-3xl font-medium`}>Starting in {count}...</p>
                <h3 className={`text-white text-lg sm:text-xl md:text-2xl lg:text-3xl font-normal ${styles.skip}`}>
                    {window.innerWidth <= 768 ? 'Tap to skip' : 'Press Enter to skip'}
                </h3>
            </div>
        </div>
    );
};

export default SplashScreen;
