import React, { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faVolumeXmark, faVolumeUp } from '@fortawesome/free-solid-svg-icons';

const MusicPlayer: React.FC = () => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(true); // Set initial state to true to reflect autoplay
  const [volume, setVolume] = useState(0.25); // Default volume to 50%
  const [sliderWidth, setsliderWidth] = useState('w-42'); // Initial slider height

  // Toggle play/pause
  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
        setsliderWidth('w-0'); // Minimize slider height
      } else {
        audioRef.current.play();
        setsliderWidth('w-42'); // Restore slider height
      }
      setIsPlaying(!isPlaying);
    }
  };

  // Change volume
  const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(event.target.value);
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume;
    }
  };

  // Automatically play the audio when the component loads
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.play().catch((error) => {
        console.warn("Autoplay was prevented by the browser:", error);
        setIsPlaying(false);
        setsliderWidth('h-0'); // Minimize slider if autoplay fails
      });
    }
  }, []);

  return (
    <div className="fixed bottom-0 right-0 my-4 mx-4 flex flex-col items-center border-2 border-[#86EFAC] rounded-lg overflow-hidden  bg-[#1b1d28b6] backdrop-saturate-150 backdrop-blur-md z-50">
      {/* Volume slider with transition */}
      <div className={`flex items-center w-full`}>
        {/* Play/Pause button */}
        <button
          onClick={togglePlayPause}
          className="audio-button w-12 h-12 items-center justify-center flex"
          aria-label={isPlaying ? 'Pause' : 'Play'}
        >
          <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} size="lg" />
        </button>

        <div className={`flex items-center  text-gray-300 transition-all duration-300 ease-in-out ${sliderWidth}`}>
          { isPlaying && (
            <>
              <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={volume}
                onChange={handleVolumeChange}
                className={`w-full h-2 bg-[#86EFAC] rounded-lg focus:outline-none accent-[#86EFAC] transition-all duration-300 ease-in-out ${sliderWidth} mx-3`}
              />
              <FontAwesomeIcon icon={volume === 0 ? faVolumeXmark : faVolumeUp} size="sm" className='text-gray-300 w-4 mr-3' />
            </>
          )}
          </div>
      </div>

      {/* Hidden audio element with autoplay */}
      <audio ref={audioRef} src="/musics/theme.mp3" preload="auto" autoPlay />
    </div>
  );
};

export default MusicPlayer;
