import { useContext, useState, useEffect, useRef } from "react";
import { useRouter } from "next/router";

import { title, subtitle } from "./components/primitives";
import DefaultLayout from "./layouts/default";

import React from 'react'; // Import React

import SplashScreen from "./components/views/splash/splash";
import Settings from "./components/views/settings/settings";
import CollectorPreview from "./components/views/collector/preview"
// import Portal from "./components/views/portal.old/portal.old"
import Portal from "./components/views/portal/portal"

import useUserSOLBalanceStore from './stores/useUserSOLBalanceStore';

// Wallet
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { log } from "console";

import BackgroundAnimation from "./components/views/background/animation";

import MusicPlayer from './components/musicPlayer';

export default function IndexPage() {
  const router = useRouter();

  const ws = useRef<WebSocket | null>(null);

  const [showSplash, setShowSplash] = useState(true);
  const [showSettings, setShowSettings] = useState(false);
  const [showPortal, setShowPortal] = useState(true);
  const [showLobby, setShowLobby] = useState(false);

  const handleSplashComplete = () => {
    setShowSplash(false); // Hide splash and show settings
    // setShowSettings(true); // Show settings view after splash
    // setShowPortal(true); // Hide settings view on confirm
  };

  const handleSettingsConfirm = () => {
    // setShowSettings(false); // Hide settings view on confirm
    // setShowPortal(true); // Hide settings view on confirm
};

  // const handleStart = () => {
  //   setShowPortal(false); // Hide settings view on confirm
  //   setShowLobby(true); // Hide settings view on confirm
  // };

  // const handleClose = () => {
  //   setShowLobby(false); // Hide settings view on confirm
  //   setShowPortal(true); // Hide settings view on confirm
  // };

  const wallet = useWallet();
  const { connection } = useConnection();

  const balance = useUserSOLBalanceStore((s) => s.balance)
  const { getUserSOLBalance } = useUserSOLBalanceStore()

  useEffect(() => {
    const handleKey = (event: KeyboardEvent) => {
      if (showSettings) {
          if (event.key === 'Enter') handleSettingsConfirm();
      // } else if (showPortal) {
      //     if (event.key === 'Enter') handleStart();
      // } else if (showLobby) {
      //     if (event.key === 'Escape') handleClose();
      } else {
          console.log('Pass');
      }
    };

    window.addEventListener('keydown', handleKey);
    return () => {
        window.removeEventListener('keydown', handleKey);
    };

  }, [showSettings, showPortal, showLobby]);

  useEffect(() => {
    if (wallet.publicKey) {
      // console.log(wallet.publicKey.toBase58())
      const interval = setInterval(() => {
        // Make sure wallet.publicKey is not null
        if (wallet.publicKey) {
          getUserSOLBalance(wallet.publicKey, connection);
        }
      }, 5000); // Call every 5 seconds
  
      // Clean up the interval when the component unmounts
      return () => {
        clearInterval(interval);
      }
    }
  }, [wallet.publicKey, connection, getUserSOLBalance])

  return (
    <DefaultLayout>
      <section className="flex flex-col items-center justify-center gap-4 py-8 md:py-4">
        <MusicPlayer />
        <BackgroundAnimation />

        {/* <div className="inline-block max-w-xl text-center justify-center">
          <h1 className={title()}>Degens Arena</h1>
          <h4 className={subtitle({ class: "mt-4" })}>
           PVP Client for Pump Fun 
          </h4>
        </div> */}

        <div>
            {showPortal ? (
              <>
                {showSplash ? (
                  <SplashScreen onComplete={handleSplashComplete} />
                ) : null}
                
                {/* <div className="relative z-50">
                  <CollectorPreview />
                </div> */}
                <div className="relative z-10">
                  <Portal />
                </div>
              </>
            ) : null}
        </div>

      </section>
    </DefaultLayout>
  );
}
